import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

function SupportPage() {
  return (
    <Box component="main" sx={{ p: 3 }}>
      <Card sx={{ minWidth: 325, maxWidth: '50vw' }}>
        <CardMedia component="img" height="200" image="/assets/images/support.jpg" alt="Contact Us">
        </CardMedia>
        <Link to="/"><Button>Back</Button></Link>
        <CardContent>
          <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
          <style type="text/css" media="screen, projection">
            @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css); 
          </style> 
          <iframe title="Feedback Form" className="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://jcxsoftware.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&searchArea=no" scrolling="no" height="500px" width="100%" frameBorder="0" >
          </iframe>
          <Typography>
            <p>Odor Reporter is a free service to the community.</p>
          </Typography>
      </CardContent>
      </Card>
    </Box>
  );
}

export default SupportPage;
