import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function ProductDescriptionPage() {
  return (
    <Box component="main" sx={{ p: 3 }}>
      <Card sx={{ minWidth: 325, maxWidth: 500 }}>
        <CardHeader title="Product Description" />
        <CardContent>
          <Typography component="div" gutterBottom>
          Introducing Odor Reporter, the must-have mobile app for residents of Doral, Florida! Designed to help you report foul odors in the environment, our app is perfect for those concerned about the impact of landfills and waste to energy facilities on the local area.  
          </Typography>
          <Typography component="div" gutterBottom>
          With Odor Reporter, you can quickly and easily report any unpleasant smells you encounter in your day-to-day life. Whether it's a strong odor emanating from a nearby landfill or an unpleasant smell coming from a waste to energy facility, you can use our app to notify the relevant authorities and make your voice heard.
          </Typography>
          <Typography component="div" gutterBottom>
          Don't let unpleasant odors spoil your quality of life. Download Odor Reporter today and start reporting foul odors in your local area!
          </Typography>
        </CardContent>
      </Card>     
    </Box>
  );
}

export default ProductDescriptionPage;
