import PrivacyPolicyPage  from './pages/privacy_policy'
import HomePage from './pages/home';
import LandfillVideo from './components/landfill-video';
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ProductDescriptionPage from './pages/product_description';
import SupportPage from './pages/support';

function App() {
  return (
    <div>
      <LandfillVideo />
      <div id="centerContainer">
        <Router>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/privacy_policy" element={<PrivacyPolicyPage/>} />
            <Route path="/product_description" element={<ProductDescriptionPage/>} />
            <Route path="/support" element={<SupportPage/>} />
          </Routes>
        </Router>
    </div>
    </div>
  )
}
export default App;
