import { Button, Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicyPage() {
  return (
    <Box component="main" sx={{ p: 3 }}>
      <Card sx={{ minWidth: '80vw', minHeight: '80vh', maxWidth: '90vw', maxHeight: '90vh' }} style={{overflowY: 'scroll'}}>
        <CardMedia component="img" height="200" image="/assets/images/privacy.jpg" alt="Privacy Policy"/>
        <CardHeader title="Privacy Policy" />
        <Link to="/"><Button>Back</Button></Link>
        <CardContent>
          <Typography>
            <p>At Odor Reporter, we are committed to protecting the privacy and security of our users. This privacy policy explains how we collect, use, and disclose personal information that you provide to us through our mobile application, Odor Reporter (the "App"). By using the App, you agree to the terms of this privacy policy.</p>

            <ol>
              <li>
                <h3>Information we collect</h3>
                <p>When you use the App, we may collect the following types of personal information:</p>
                <ul>
                  <li>Personal information you provide to us directly: This includes information you provide when you create an account, submit odor reports, or contact us for support. This information may include your name, email address, phone number, and other contact information.</li>
                  <li>Information we collect automatically: When you use the App, we may collect certain information automatically, such as your device type, device ID, IP address, and location information. We may also collect information about how you use the App, such as the pages you visit and the features you use.</li>
                </ul>
              </li>
              <li>
              <h3>How we use your information</h3>
                <p>We use the information we collect to:</p>


                <p>Provide and improve the App: We use your information to operate, maintain, and improve the App, and to develop new features and functionality.
                Communicate with you: We may use your information to communicate with you about the App, including responding to your inquiries and providing support.
                Send you marketing communications: With your consent, we may send you marketing communications about our products and services that we think may be of interest to you.
                Enforce our policies: We may use your information to enforce our policies and terms of service.</p>
              </li>
              <li>
                <h3>How we share your information</h3>
                <p>We do not share your personal information with third parties except as follows:</p>

                <p>With your consent: We may share your information with third parties with your consent.</p>
                <p>With service providers: We may share your information with service providers who perform services on our behalf, such as hosting and data analysis.
                For legal reasons: We may share your information if we believe that disclosure is necessary to comply with the law, enforce our policies, or protect our rights, property, or safety, or the rights, property, or safety of others.</p>
              </li>
              <li>
                <h3>Data retention</h3>
                <p>We retain your personal information for as long as necessary to provide the App and fulfill the purposes described in this privacy policy. When we no longer need your personal information, we will securely delete or destroy it.</p>
              </li>
              <li>
                <h3>Security</h3>
                <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the security of your information.</p>
              </li>
              <li>
                <h3>Children</h3>
                <p>The App is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided personal information through the App, please contact us at the email address below and we will delete the information.</p>
              </li>
              <li>
                <h3>Changes to this privacy policy</h3>
                <p>We may update this privacy policy from time to time. If we make material changes, we will notify you by email or by posting a notice in the App. Your continued use of the App after we make changes indicates that you accept the updated privacy policy.</p>
              </li>
              <li>
                <h3>Contact us</h3>
                <p>If you have any questions about this privacy policy or our practices, please contact us at <a href="mailto:support@odorreporter.com">support@odorreporter.com.</a></p>
              </li>
            </ol>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PrivacyPolicyPage;
