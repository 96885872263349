import './home.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { CardHeader, CardMedia } from '@mui/material';

function HomePage() {
  return (
      <Card sx={{ minWidth: 325, maxWidth: '50vw' }}>
        <CardMedia component="img" height="200" image="/assets/images/man-with-gasmask.jpg" alt="Act Now">
        </CardMedia>
        <CardHeader title="Odor Reporter" />
        <CardContent>
          <Typography component="div" gutterBottom>
          Introducing Odor Reporter, the must-have mobile app for residents of Doral, Florida! Designed to help you report foul odors in the environment, our app is perfect for those concerned about the impact of landfills and waste to energy facilities on the local area.  
          </Typography>
        </CardContent>
        <div id="storeButtons">
          <Button id="appleButton" href="https://apps.apple.com/us/app/odor-reporter/id6446068487">
            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1617321600" alt="Download on the App Store"/>
          </Button>
          <Button id="googleButton" href="https://play.google.com/store/apps/details?id=com.jcxsoftware.odorpollution">
            <img height="60" src="/assets/images/google-play-badge.png" alt="Download on the Play Store"/>
          </Button>
        </div>
        <CardActions>
          <Link to="/privacy_policy"><Button size="small">Privacy Policy</Button></Link>
          <Link to="/support"><Button size="small">Support</Button></Link>
        </CardActions>
      </Card>     
    );
  }

export default HomePage;
